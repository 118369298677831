<template>
  <v-container fluid class="fix-bottom">
    <v-layout justify-center align-center>
      <v-flex shrink>
        <a target="_blank" href="https://www.nanocrowd.com" class="branding">
          &copy;{{ new Date().getFullYear() }} <strong>Nanocrowd</strong>
        </a>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {},
}
</script>

<style scoped>
.fix-bottom {
  position: absolute;
  bottom: 0px;
}

.branding {
  color: #fff;
  text-decoration: none;
}
</style>
