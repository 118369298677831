import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Tierone from '../views/Tierone.vue'
import Tiertwo from '../views/Tiertwo.vue'
import Tierthree from '../views/Tierthree.vue'
import Title from '../views/TitleDetails.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'none' },
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
    meta: { transition: 'zoom' },
  },
  {
    path: '/tierone/:tierKey',
    name: 'Tierone',
    component: Tierone,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/tiertwo/:tierKey',
    name: 'Tiertwo',
    component: Tiertwo,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/tierthree/:tierKey',
    name: 'Tierthree',
    component: Tierthree,
    props: true,
    meta: { transition: 'zoom' },
  },
  {
    path: '/title/:id',
    name: 'Title',
    component: Title,
    props: true,
    meta: { transition: 'zoom' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

// router.beforeEach((to, from, next) => {
// console.log('before: ', to, from)
// next()
// })

// router.afterEach((to, from) => {
// console.log('after: ', to, from)
// })
export default router
