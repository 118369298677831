<template>
  <v-card elevation="0" color="#272727">
    <v-speed-dial
      v-model="fab"
      :top="top"
      :right="right"
      :direction="direction"
    >
      <template v-slot:activator>
        <v-btn x-small elevation="0" v-model="fab" class="no-background-hover">
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-share-variant </v-icon>
          <!-- <img v-if="fab" src="../assets/images/icons/close.png" /> -->
          <!-- <img v-else src="../assets/images/icons/share-variant.png" /> -->
        </v-btn>
      </template>
      <v-btn fab dark x-small>
        <ShareNetwork
          network="twitter"
          :url="getCurrentRoute"
          title="Check out what I just created to watch!"
          hashtags="nanocrowd,myGenre"
          twitterUser=""
        >
          <v-icon>mdi-twitter</v-icon>
          <!-- <img src="../assets/images/icons/twitter.png" /> -->
        </ShareNetwork>
      </v-btn>
      <v-btn fab dark x-small>
        <ShareNetwork
          network="facebook"
          :url="getCurrentRoute"
          title="Check out what I just created to watch!"
          hashtags="nanocrowd,myGenre"
        >
          <!-- <img src="../assets/images/icons/facebook.png" /> -->
          <v-icon>mdi-facebook</v-icon>
        </ShareNetwork>
      </v-btn>
      <v-btn fab dark x-small>
        <ShareNetwork
          network="linkedin"
          :url="getCurrentRoute"
          title="Check out what I just created to watch!"
        >
          <!-- <img src="../assets/images/icons/linkedin.png" /> -->
          <v-icon>mdi-linkedin</v-icon>
        </ShareNetwork>
      </v-btn>
      <v-btn fab dark x-small>
        <ShareNetwork
          network="reddit"
          :url="getCurrentRoute"
          title="Check out what I just created to watch!"
          description=""
        >
          <!-- <img src="../assets/images/icons/reddit.png" /> -->
          <v-icon>mdi-reddit</v-icon>
        </ShareNetwork>
      </v-btn>
      <v-btn fab dark x-small>
        <ShareNetwork
          url=""
          network="email"
          title="Check out what I found to watch"
          :description="getEmailDescription"
        >
          <!-- <img src="../assets/images/icons/email.png" /> -->
          <v-icon>mdi-email</v-icon>
        </ShareNetwork>
      </v-btn>
    </v-speed-dial>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    direction: 'bottom',
    fab: false,
    top: true,
    right: true,
    bottom: false,
  }),
  computed: {
    getCurrentRoute() {
      return decodeURI(location.href)
      // return 'https://app.nanocrowd.com/title/4029'
    },
    getEmailDescription() {
      return `I found this one on Nanocrowd and thought you might like it too. ${decodeURI(
        location.href
      )}`
    },
  },
}
</script>

<style>
.v-speed-dial--right {
  right: 0 !important;
}

.v-speed-dial--top {
  top: 0 !important;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
