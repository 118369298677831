import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    viewKey: 1,
    currentKeywords: [],
    loading: true,
    token: null,
  },
  getters: {
    getCurrentKeywords: (state) => () => {
      return [...state.currentKeywords]
    },
    getLoading: (state) => () => {
      return state.loading
    },
  },
  mutations: {
    SET_CURRENT_KEYWORDS(state, data) {
      state.currentKeywords = data
    },
    CLEAR_CURRENT_KEYWORDS(state) {
      state.currentKeywords = []
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
    SET_TOKEN(state, data) {
      state.token = data.token
    },
  },
  actions: {
    setCurrentKeywords({ commit }, data) {
      commit('SET_CURRENT_KEYWORDS', data)
    },
    clearCurrentKeywords({ commit }) {
      commit('CLEAR_CURRENT_KEYWORDS')
    },
    setLoading({ commit }, data) {
      commit('SET_LOADING', data)
    },
    async fetchToken({ commit }) {
      let response = await dataApi.getToken()
      if (response.data) {
        commit('SET_TOKEN', response.data)
      }
    },
    async getToken({ commit, state }) {
      if (!state.token) {
        let response = await dataApi.getToken()
        if (response.data) {
          commit('SET_TOKEN', response.data)
        }
      }
      return state.token
    },
  },
}
