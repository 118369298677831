import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    titles: [],
    token: null,
  },
  getters: {
    getTitles: (state) => () => {
      return state.titles
    },
    getNcTitleId: (state) => (id) => {
      return state.titles.find((item) => item.id === id)
    },
  },
  mutations: {
    SET_TITLES(state, data) {
      // go through the list and set up a transposition id
      for (let x = 0; x < data.length; x++) {
        data[x].id = x + 1
      }
      state.titles = data
    },
    SET_TOKEN(state) {
      state.token = this.state.shared.token
    },
  },
  actions: {
    async fetchTitles({ commit, state }) {
      if (!state.token) {
        commit('SET_TOKEN')
      }
      let response = await dataApi.getTitles(state.token)
      if (response.data) {
        commit('SET_TITLES', response.data)
      }
    },
  },
}
