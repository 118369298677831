<template>
  <v-card
    v-if="hasData"
    class="d-flex justify-center"
    :class="getClass"
    max-width="200"
    dark
    flat
    @click="onTitleDetails(item.ncTitleId)"
  >
    <div class="item">
      <v-lazy
        v-model="isActive"
        :options="{
          threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
      >
        <v-img
          :lazy-src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/medium/${item.ncTitleId}.jpg`"
          :src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/medium/${item.ncTitleId}.jpg`"
          min-height="185"
          min-width="130"
          max-height="185"
          max-width="130"
          class="rounded"
        ></v-img>
      </v-lazy>
      <v-row class="mt-1">
        <v-col cols="12" class="pa-0">
          <v-rating
            :value="getRating(item.crowdRating)"
            half-increments
            readonly
            background-color="orange lighten-3"
            color="orange"
            x-small
            class="pl-0 d-flex justify-center"
          ></v-rating>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col cols="12" class="pa-0">
          <v-card-text class="text-truncate pa-1 ml-2" style="max-width: 130px"
            >{{ item.title }} ({{ item.year }})</v-card-text
          >
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    isActive: false,
  }),
  methods: {
    onTitleDetails(ncTitleId) {
      this.$router.push(`/title/${ncTitleId}`)
    },
    getRating(data) {
      const getStarValue = (data) => {
        const pieces = `${data}`.split('.')
        let result = []
        if (pieces.length > 1) {
          const afterDecimalStringValue = `0.${pieces[1]}`
          const afterDecimalFloatValue = parseFloat(afterDecimalStringValue)
          if (afterDecimalFloatValue <= 0.25) {
            result[0] = pieces[0]
            result[1] = '.'
            result[2] = '0'
          } else if (
            afterDecimalFloatValue > 0.25 &&
            afterDecimalFloatValue <= 0.75
          ) {
            result[0] = pieces[0]
            result[1] = '.'
            result[2] = '5'
          } else {
            result[0] = `${parseInt(pieces[0]) + 1}`
            result[1] = '.'
            result[2] = '0'
          }
          return parseFloat(result.join(''))
        } else {
          return parseFloat(pieces[0])
        }
      }
      return getStarValue(data)
    },
  },
  computed: {
    hasData() {
      return !!this.item.ncTitleId
    },
    getClass() {
      if (this.item.class) {
        return this.item.class
      } else {
        return 'fullOpacity'
      }
    },
  },
  mounted() {},
}
</script>

<style scoped>
.item {
  padding: 16px 16px;
  border-radius: 3px;
  background: #444040;
}
.fullOpacity {
  opacity: 1;
}

.reducedOpacity {
  opacity: 0.2;
}
</style>
