import axios from 'axios'

// const baseURL = 'http://localhost:3000'
const baseURL = 'https://bananastreampie.com'
// const baseURL = 'http://137.184.37.198:3000'
// const baseURL = 'http://138.68.49.175:3000'
// const baseURL = 'http://137.184.117.207:3100'

const apiClient = axios.create({
  baseURL: `${baseURL}`,
  // timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  async getStartKeywords(token) {
    return await apiClient.get('/api/fundamentals', {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async getProviders(token) {
    return await apiClient.get('/api/providers', {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async getKeywordsAndTitles(token, key) {
    return await apiClient.get(`/api/tiers/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async getTitleProviders(token, imdbId) {
    return await apiClient.get(`/api/streams/${imdbId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async getTitleDetails(token, ncTitleId) {
    return await apiClient.get(`/api/titles/${ncTitleId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async getTitles(token) {
    return await apiClient.get(`/api/titlesearch/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async playTitle(token, imdbId, url) {
    return await apiClient.get(`/api/streams/play/${imdbId}/${url}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  async getToken() {
    const response = await apiClient.get(`/api/init/token`)
    return response
  },
}
