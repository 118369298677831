exports.handleResponse = (data) => {
  const result = []

  function compare(a, b) {
    if (a.providerType === b.providerType) {
      let comparison = 0
      if (a.titleCount > b.titleCount) {
        comparison = -1
      } else if (a.titleCount < b.titleCount) {
        comparison = 1
      }
      return comparison
    }
    if (a.providerType > b.providerType) {
      return 1
    }
    return -1
  }

  if (data.titleList) {
    let list = []
    for (let x = 0; x < data.titleList.length; x++) {
      list = [
        ...list,
        ...data.titleList[x].providers.map((item) => {
          return {
            providerId: item.providerId,
            providerType: item.providerType,
          }
        }),
      ]
    }

    const uniqueList = [...new Set(list.map((o) => JSON.stringify(o)))].map(
      (s) => JSON.parse(s)
    )

    for (let x = 0; x < uniqueList.length; x++) {
      result.push(uniqueList[x])
    }
    let titleList = []
    // get the list of title ids, with a flattened list of offers for each title
    titleList = data.titleList.map((item) => {
      return {
        ncTitleId: item.ncTitleId,
        crowdRating: item.crowdRating,
        title: item.title,
        year: item.year,
        providers: item.providers,
      }
    })
    let flattenedData = []
    for (let x = 0; x < titleList.length; x++) {
      for (let xx = 0; xx < titleList[x].providers.length; xx++) {
        if (
          titleList[x].providers[xx].offerType === 'flatrate' ||
          titleList[x].providers[xx].offerType === 'free' ||
          titleList[x].providers[xx].offerType === 'ads'
        ) {
          let titleRecencyCode = 0
          if (titleList[x].providers[xx].age === 0) {
            // new
            titleRecencyCode = 1
          } else if (
            titleList[x].providers[xx].age > 0 &&
            titleList[x].providers[xx].age < 8
          ) {
            // one week
            titleRecencyCode = 2
          } else if (
            titleList[x].providers[xx].age > 7 &&
            titleList[x].providers[xx].age < 30
          ) {
            // one month
            titleRecencyCode = 3
          } else {
            // permanent
            titleRecencyCode = 4
          }

          const obj = {
            ncTitleId: titleList[x].ncTitleId,
            crowdRating: titleList[x].crowdRating,
            title: titleList[x].title,
            year: titleList[x].year,

            offerType: titleList[x].providers[xx].offerType,
            providerId: titleList[x].providers[xx].providerId,
            providerType: titleList[x].providers[xx].providerType,
            age: titleList[x].providers[xx].age,
            titleRecencyCode,
          }
          flattenedData.push(obj)
        }
      }
    }

    const subscriptions = [...flattenedData]

    // go through each provider and get the individual title ids
    for (let x = 0; x < result.length; x++) {
      const providerAll = flattenedData
        .filter((item) => item.providerId === result[x].providerId)
        .map((item) => item.ncTitleId)
      const providerSubscriptions = subscriptions
        .filter((item) => item.providerId === result[x].providerId)
        .map((item) => {
          return {
            ncTitleId: item.ncTitleId,
            crowdRating: item.crowdRating,
            title: item.title,
            year: item.year,
            age: item.age,
            titleRecencyCode: item.titleRecencyCode,
          }
        })

      result[x].subscriptions = {}
      // this is a tricky object array dedupe function.
      result[x].subscriptions.allTitles = providerSubscriptions.filter(
        (v, i, a) => a.findIndex((t) => t.ncTitleId === v.ncTitleId) === i
      )

      // add a class property to every element of the array
      result[x].subscriptions.allTitles = result[x].subscriptions.allTitles.map(
        (item) => ({
          ...item,
          class: 'fullOpacity',
        })
      )

      // this is a tricky array dedupe function.
      result[x].all = providerAll.filter(
        (v, i, a) => a.findIndex((t) => t === v) === i
      )

      for (let xx = 0; xx < result[x].subscriptions.allTitles.length; xx++) {
        // record the original sort for later
        result[x].subscriptions.allTitles[xx].originalSequence = xx + 1
      }

      // do three more sorts
      // - one with the 'new' in the front,
      let firstSet = result[x].subscriptions.allTitles.filter((item) => {
        if (item.age === 0) {
          const result = { ...item }
          return result
        }
      })

      let lastSet = result[x].subscriptions.allTitles.filter((item) => {
        if (item.age > 0) {
          const result = { ...item }
          return result
        }
      })

      lastSet = lastSet.map((item) => ({
        ...item,
        class: 'reducedOpacity',
      }))

      result[x].subscriptions.newTitles = [
        ...firstSet.sort((a, b) => parseInt(a.age) - parseInt(b.age)),
      ]
      result[x].subscriptions.newTitles.push(...lastSet)

      // - one with the '7 days' in the front,
      firstSet = result[x].subscriptions.allTitles.filter((item) => {
        if (item.age < 8) {
          const result = { ...item }
          return result
        }
      })
      lastSet = result[x].subscriptions.allTitles.filter((item) => {
        if (item.age > 7) {
          const result = { ...item }
          return result
        }
      })

      lastSet = lastSet.map((item) => ({
        ...item,
        class: 'reducedOpacity',
      }))

      result[x].subscriptions.sevenDaysTitles = [
        ...firstSet.sort((a, b) => parseInt(a.age) - parseInt(b.age)),
      ]
      result[x].subscriptions.sevenDaysTitles.push(...lastSet)

      // - one with the '30 days' in the front,
      firstSet = result[x].subscriptions.allTitles.filter((item) => {
        if (item.age < 31) {
          const result = { ...item }
          return result
        }
      })
      lastSet = result[x].subscriptions.allTitles.filter((item) => {
        if (item.age > 30) {
          const result = { ...item }
          return result
        }
      })

      lastSet = lastSet.map((item) => ({
        ...item,
        class: 'reducedOpacity',
      }))

      result[x].subscriptions.thirtyDaysTitles = [
        ...firstSet.sort((a, b) => parseInt(a.age) - parseInt(b.age)),
      ]
      result[x].subscriptions.thirtyDaysTitles.push(...lastSet)

      result[x].titleCount = result[x].subscriptions.allTitles.length
    }
    // sort the result in descending order
    const sortedResult = result.sort(compare)
    return sortedResult
  }
  return result
}
