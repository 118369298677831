<template>
  <v-card class="mb-0" flat outlined height="396" width="100%">
    <nc-title-list-dialog
      v-if="showDialog"
      :title="title"
      :type="type"
      :items="items"
    ></nc-title-list-dialog>
    <nc-title-horizontal-list
      :type="type"
      :items="items"
    ></nc-title-horizontal-list>
  </v-card>
</template>

<script>
import NcTitleListDialog from './NcTitleListDialog'
import NcTitleHorizontalList from './NcTitleHorizontalList'
export default {
  components: {
    NcTitleListDialog,
    NcTitleHorizontalList,
  },

  name: 'TitleCollection',
  props: ['title', 'type', 'items'],
  data: () => ({
    showDialog: true,
  }),
  computed: {},
  mounted() {
    // console.log(`mounted title collection: ${this.title}`)
  },
}
</script>

<style scoped></style>
