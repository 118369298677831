import dataApi from '../../services/dataApi'
import utils from '../shared/utils'

export default {
  namespaced: true,
  state: {
    keywords: [],
    allTitles: [],
    providerTypeTitles: [],
    selectedKeyword: null,
    token: null,
    scrollPosition: 0,
  },
  getters: {
    getKeywords: (state) => () => {
      if (state.keywords) {
        return [...state.keywords]
      }
      return []
    },
    getTitles: (state) => () => {
      if (state.allTitles) {
        return [...state.allTitles]
      }
      return []
    },
    getProviderTitles: (state) => (type) => {
      const result = state.providerTypeTitles.filter(
        (item) => item.providerType === type
      )
      return result
    },
    getSelected: (state) => () => {
      return state.selectedKeyword
    },
    getScrollPosition: (state) => () => {
      return state.scrollPosition
    },
  },
  mutations: {
    SET_KEYWORDS(state, data) {
      if (data.length) {
        const subtierList = data[0].subtierList
        state.keywords = subtierList.map((item) => item.subtier)
      } else {
        state.keywords = []
      }
    },
    SET_TITLES(state, data) {
      if (data.length) {
        state.allTitles = data[0].titleList
        state.providerTypeTitles = utils.handleResponse(data[0])
      } else {
        state.allTitles = []
        state.providerTypeTitles = []
      }
    },
    SET_SELECTED_KEYWORD(state, data) {
      state.selectedKeyword = data
    },
    CLEAR_CURRENT_KEYWORD(state) {
      state.selectedKeyword = null
    },
    CLEAR_TITLES(state) {
      state.allTitles = []
      state.providerTypeTitles = []
    },
    CLEAR_KEYWORDS(state) {
      state.keywords = []
    },
    SET_PROGRESS_INDICATOR_ON() {
      this.state.shared.loading = true
    },
    SET_PROGRESS_INDICATOR_OFF() {
      const that = this
      setTimeout(() => {
        that.state.shared.loading = false
      }, 1000)
    },
    SET_TOKEN(state) {
      state.token = this.state.shared.token
    },
    SET_SCROLL_POSITION(state, data) {
      state.scrollPosition = data
    },
  },
  actions: {
    async fetchKeywordsAndTitles({ commit, state }, id) {
      commit('CLEAR_KEYWORDS')
      commit('CLEAR_TITLES')
      commit('SET_PROGRESS_INDICATOR_ON')
      if (!state.token) {
        commit('SET_TOKEN')
      }
      let response = await dataApi.getKeywordsAndTitles(state.token, id)
      commit('SET_PROGRESS_INDICATOR_OFF')
      if (response.data) {
        commit('SET_KEYWORDS', response.data)
        commit('SET_TITLES', response.data)
      }
    },
    setSelected({ commit }, keyword) {
      commit('SET_SELECTED_KEYWORD', keyword)
    },
    clearCurrentKeyword({ commit }) {
      commit('CLEAR_CURRENT_KEYWORD')
    },
    setScrollPosition({ commit }, scrollPosition) {
      commit('SET_SCROLL_POSITION', scrollPosition)
    },
  },
}
