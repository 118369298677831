<template>
  <div>
    <app-toolbar id="start" title="Nanocrowd"></app-toolbar>
    <v-container class="mt-16">
      <v-card>
        <v-card-title class="pl-0 pt-0">
          <div class="text-xl-h3 dotted-left-border yellow-border pl-4">
            {{ getScreenText }}
          </div>
        </v-card-title>
      </v-card>

      <v-card class="d-flex justify-space-between flex-wrap">
        <div class="card" v-for="keyword in getKeywords" :key="keyword">
          <v-chip
            :key="keyword"
            class="mb-4 mr-2 keyword-button border-1"
            outlined
            label
            ripple
            x-large
            color="white"
            @click="onClick(keyword)"
          >
            {{ keyword }}
          </v-chip>
        </div>
      </v-card>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter.vue'
import AppToolbar from '../components/AppToolbar'

export default {
  components: {
    AppToolbar,
    AppFooter,
  },
  data: () => ({
    loading: true,
  }),
  methods: {
    onClick(keyword) {
      setTimeout(() => {
        this.$store.dispatch('tierone/setScrollPosition', 0)
        this.$store.dispatch('start/setSelected', keyword)
        this.$router.push(`/tierone/${keyword}`)
      }, 300)
    },
  },
  computed: {
    getText() {
      const searchOption = this.$store.getters.getSearchOption()
      if (searchOption === 'provider') {
        return [
          `Can't decide? Get Inspired!`,
          'Here are some popular word combinations',
        ]
      } else {
        return [
          `Can't decide? Get Inspired!`,
          'Here are some popular word combinations',
        ]
      }
    },
    getKeywords() {
      return this.$store.getters['start/getKeywords']()
    },
    getMenuItems() {
      return []
    },
    getScreenText() {
      const option = this.$store.getters.getSearchOption()
      if (option === 'provider') {
        return 'What do you like?'
      } else {
        return 'Pick a genre you love...'
      }
    },
  },
  async mounted() {
    this.loading = false
    await this.$store.dispatch('shared/getToken')
    this.$store.dispatch('start/clearCurrentKeyword')
    this.$store.dispatch('tierone/clearCurrentKeyword')
    this.$store.dispatch('tiertwo/clearCurrentKeyword')
    this.$store.dispatch('tierthree/clearCurrentKeyword')
    this.$store.dispatch('setCurrentLevel', 1)
  },
}
</script>

<style scoped>
.border-1 {
  border-width: 2px;
  border-color: #ff8721 !important;
}

.keyword-button {
  height: 44px;
  border-radius: 22px;
}

.dotted-left-border {
  border-left-style: dotted;
}

.yellow-border {
  border-color: #ffc12d;
}

/* .fix-bottom {
  position: absolute;
  bottom: 0px;
} */
</style>
