<template>
  <v-app>
    <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
      <!-- <transition name="slide-fade"> -->
      <router-view :key="$route.fullPath" />
      <!-- </transition> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({}),
  computed: {},
  async created() {
    this.$vuetify.theme.dark = true
  },
  async mounted() {
    await this.$store.dispatch('shared/fetchToken')
    await this.$store.dispatch('start/fetchKeywords')
    await this.$store.dispatch('providers/fetchProviders')
    await this.$store.dispatch('titleSearch/fetchTitles')
  },
}
</script>

<style>
body {
  background-color: #272727;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/* .vhl-item {
  padding-top: 29px !important;
} */

/* vue-horizontal-list scroller items */
/* .nc-item {
  padding-top: 16px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 47px !important;
} */

/* .vhl-navigation {
  margin-top: 0px !important;
}

.keyword-list .vhl-btn-right {
  margin-right: -29px !important;
}

.keyword-list .vhl-btn-left {
  margin-left: -29px !important;
}
*/
.vhl-btn-right {
  opacity: 0.5 !important;
  margin-right: 3px !important;
}

.vhl-btn-left {
  opacity: 0.5 !important;
  margin-left: 3px !important;
}
/*
.vhl-list {
  padding-bottom: 20px !important;
}
 */
</style>
