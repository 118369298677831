import Vue from 'vue'
import Vuex from 'vuex'
import shared from './modules/shared'
import titleDetails from './modules/titleDetails'
import start from './modules/start'
import providers from './modules/providers'
import tierone from './modules/tierone'
import tiertwo from './modules/tiertwo'
import tierthree from './modules/tierthree'
import titleSearch from './modules/titleSearch'
import keywordProviders from './modules/keywordProviders'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentLevel: 0,
    searchOption: 'movie',
    currentKeywords: '',
    newAtStreaming: 'all',
    offerType: '1',
    showStreamingOffers: true,
    showFreeWithAdsOffers: false,
    showCableBundledOffers: false,
    showFilterSlideIn: false,
  },
  getters: {
    getCurrentLevel: (state) => () => {
      return state.currentLevel
    },
    getSearchOption: (state) => () => {
      return state.searchOption
    },
    getCurrentKeywords: (state) => () => {
      return state.currentKeywords
    },
    getCurrentOfferType: (state) => () => {
      return state.offerType
    },
    getNewAtStreaming: (state) => () => {
      return state.newAtStreaming
    },
    isStreamingOffers: (state) => () => {
      return state.showStreamingOffers
    },
    isFreeWithAdsOffers: (state) => () => {
      return state.showFreeWithAdsOffers
    },
    isCableBundledOffers: (state) => () => {
      return state.showCableBundledOffers
    },
    showFilterSlideIn: (state) => () => {
      return state.showFilterSlideIn
    },
  },
  mutations: {
    SET_CURRENT_LEVEL(state, level) {
      state.currentLevel = level
    },
    SET_SEARCH_OPTION(state, option) {
      state.searchOption = option
    },
    SET_CURRENT_KEYWORDS(state, keywords) {
      state.currentKeywords = keywords
    },
    SET_NEW_AT_STREAMING(state, data) {
      if (data !== state.newAtStreaming) state.newAtStreaming = data
    },
    TOGGLE_SHOW_STREAMING_OFFERS(state) {
      state.offerType = '1'
      state.showStreamingOffers = true
      state.showFreeWithAdsOffers = false
      state.showCableBundledOffers = false
    },
    TOGGLE_SHOW_FREE_WITH_ADS_OFFERS(state) {
      state.offerType = '2'
      state.showStreamingOffers = false
      state.showFreeWithAdsOffers = true
      state.showCableBundledOffers = false
    },
    TOGGLE_SHOW_CABLE_BUNDLED_OFFERS(state) {
      state.offerType = '3'
      state.showStreamingOffers = false
      state.showFreeWithAdsOffers = false
      state.showCableBundledOffers = true
    },
    TOGGLE_OFF_ALL_OFFERS(state) {
      state.showStreamingOffers = false
      state.showFreeWithAdsOffers = false
      state.showCableBundledOffers = false
    },
    TOGGLE_FILTER_SLIDE_IN(state) {
      state.showFilterSlideIn = !state.showFilterSlideIn
    },
  },
  actions: {
    toggleShowStreamingOffers({ commit }) {
      commit('TOGGLE_SHOW_STREAMING_OFFERS')
    },
    toggleShowFreeWithAdsOffers({ commit }) {
      commit('TOGGLE_SHOW_FREE_WITH_ADS_OFFERS')
    },
    toggleShowCableBundledOffers({ commit }) {
      commit('TOGGLE_SHOW_CABLE_BUNDLED_OFFERS')
    },
    toggleOffAllOffers({ commit }) {
      commit('TOGGLE_OFF_ALL_OFFERS')
    },
    toggleFilterSlideIn({ commit }) {
      commit('TOGGLE_FILTER_SLIDE_IN')
    },
    setCurrentLevel({ commit }, level) {
      commit('SET_CURRENT_LEVEL', level)
    },
    setSearchOption({ commit }, option) {
      commit('SET_SEARCH_OPTION', option)
    },
    setCurrentKeywords({ commit }, keywords) {
      commit('SET_CURRENT_KEYWORDS', keywords)
    },
    setNewAtStreaming({ commit }, data) {
      commit('SET_NEW_AT_STREAMING', data)
    },
  },
  modules: {
    shared,
    providers,
    start,
    tierone,
    tiertwo,
    tierthree,
    titleDetails,
    keywordProviders,
    titleSearch,
  },
})
