<template>
  <v-card class="d-flex">
    <progress-indicator></progress-indicator>
    <v-app-bar
      app
      color="gray"
      dark
      class="mb-6 justify-start"
      extension-height="35"
    >
      <v-btn
        v-show="!drawer"
        x-small
        elevation="0"
        @click="backbuttonClicked()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-show="!drawer" x-small elevation="0" @click="goHome()">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        x-small
        elevation="0"
        @click="drawer = !drawer"
        class="no-background-hover"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <title-search v-show="drawer"></title-search>
      <social-sharing v-show="!drawer"></social-sharing>
      <v-btn x-small elevation="0" v-show="!drawer" @click="onFilterSlideIn()">
        <v-icon size="25">mdi-tune-variant</v-icon>
      </v-btn>
      <more-menu v-show="!drawer"></more-menu>
    </v-app-bar>
    <filter-slide-in></filter-slide-in>
  </v-card>
</template>

<script>
import SocialSharing from './SocialSharing'
import MoreMenu from './MoreMenu'
import TitleSearch from './TitleSearch'
import ProgressIndicator from '../components/ProgressIndicator'
import FilterSlideIn from '../components/FilterSlideIn'

export default {
  name: 'AppToolbar',
  props: ['id', 'title'],
  components: {
    SocialSharing,
    MoreMenu,
    TitleSearch,
    ProgressIndicator,
    FilterSlideIn,
  },
  data: () => ({ drawer: null }),
  methods: {
    // backbuttonClicked() {
    //   this.$router.back()
    // },
    // goHome() {
    //   this.$router.push(`/start`)
    // },
    backbuttonClicked() {
      setTimeout(() => {
        this.$router.back()
      }, 100)
    },
    goHome() {
      setTimeout(() => {
        this.$router.push(`/start`)
      }, 100)
    },
    onFilterSlideIn() {
      setTimeout(() => {
        this.$store.dispatch('toggleFilterSlideIn')
      }, 100)
    },
  },
  mounted() {},
}
</script>
<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
