<template>
  <div>
    <app-toolbar id="tierone" title="Nanocrowd"></app-toolbar>
    <v-container fluid class="pl-0 pr-0">
      <v-card flat width="100%">
        <v-container fluid class="pa-0">
          <nc-keyword-collection
            v-if="hasKeywords"
            :items="getKeywords"
            tier="tierone"
          ></nc-keyword-collection>
        </v-container>
      </v-card>

      <v-card flat>
        <v-container fluid class="pb-0 pl-0 pr-0">
          <nc-title-collection
            v-if="hasTitles"
            :title="getSelected"
            :items="getTitles"
            type="tier"
          ></nc-title-collection>
        </v-container>
      </v-card>

      <v-card flat v-if="hasProviders" width="100%">
        <v-container fluid class="pl-0 pr-0">
          <v-card-title class="pb-0 text-caption text-lg-h6">
            {{ getScreenText }}
          </v-card-title>
          <provider-list v-if="hasProviders" tier="tierone"></provider-list>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AppToolbar from '../components/AppToolbar'
import ProviderList from '../components/ProviderList'
// import BottomNavigation from '../components/BottomNavigation'
import NcKeywordCollection from '../components/NcKeywordLists/NcKeywordCollection'
import NcTitleCollection from '../components/NcTitleLists/NcTitleCollection'
import ProviderTypes from '../shared/ProviderTypes'

export default {
  name: 'tierOne',
  props: ['tierKey'],
  components: {
    AppToolbar,
    ProviderList,
    // BottomNavigation,
    NcKeywordCollection,
    NcTitleCollection,
  },
  data: () => ({}),
  methods: {
    handleScroll() {
      this.$store.dispatch('tierone/setScrollPosition', window.scrollY)
    },
  },
  computed: {
    hasKeywords() {
      const data = this.$store.getters['tierone/getKeywords']()
      return data.length > 0 ? true : false
    },
    hasTitles() {
      const data = this.$store.getters['tierone/getTitles']()
      return data.length > 0 ? true : false
    },
    hasProviders() {
      const data = this.$store.getters[`tierone/getProviderTitles`](
        ProviderTypes.STREAMING
      )
      return data.length > 0 ? true : false
    },
    getKeywords() {
      return this.$store.getters['tierone/getKeywords']()
    },
    getTitles() {
      const data = this.$store.getters['tierone/getTitles']()
      return data.slice(0, 100)
    },
    getSelected() {
      return `Results for ${this.$store.getters['start/getSelected']()}`
    },
    getScreenText() {
      if (this.$store.getters.isStreamingOffers()) {
        return 'Subscription'
      } else if (this.$store.getters.isFreeWithAdsOffers()) {
        return 'Free with ads'
      } else if (this.$store.getters.isCableBundledOffers()) {
        return 'Cable / bundles'
      } else {
        return 'Loading offers...'
      }
    },
  },
  created() {
    // console.log('created')
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  beforeMount() {
    // console.log('beforeMount')
  },
  async mounted() {
    // console.log('mounted')
    await this.$store.dispatch('shared/getToken')
    this.$store.dispatch('start/setSelected', this.tierKey)
    const currentKeywords = this.$store.getters['getCurrentKeywords']()
    if (currentKeywords !== this.tierKey) {
      this.$store.dispatch('tierone/fetchKeywordsAndTitles', this.tierKey)
      this.$store.dispatch('setCurrentKeywords', this.tierKey)
    }
    this.$store.dispatch('setCurrentLevel', 2)
    const scrollPosition = this.$store.getters['tierone/getScrollPosition']()
    window.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth',
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {},
}
</script>

<style scoped>
.keyword {
  color: #ff8721;
}

.dotted-left-border {
  border-left-style: dotted;
  border-color: #ffc12d;
}

.yellow-text {
  color: #ffc12d;
}

.list-background {
  background-color: #121212;
  /* background-color: gray; */
}
</style>
