import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    providers: [],
    token: null,
  },
  getters: {
    getProviders: (state) => () => {
      return state.providers
    },
    getProviderImage: (state) => (providerId) => {
      const data = state.providers.find(
        (item) => item.jwProviderId === providerId
      )
      return data ? data.iconUrl : ''
    },
    getProviderName: (state) => (providerId) => {
      const data = state.providers.find(
        (item) => item.jwProviderId === providerId
      )
      return data ? data.providerName : ''
    },
    getProviderSubUrl: (state) => (providerId) => {
      const data = state.providers.find(
        (item) => item.jwProviderId === providerId
      )
      return data && data.signupUrl ? data.signupUrl : ''
    },
    getProviderSynopsis: (state) => (providerId) => {
      const data = state.providers.find(
        (item) => item.jwProviderId === providerId
      )
      return data && data.providerSynopsis ? data.providerSynopsis : ''
    },
  },
  mutations: {
    SET_PROVIDERS(state, data) {
      state.providers = data
    },
    SET_TOKEN(state) {
      state.token = this.state.shared.token
    },
  },
  actions: {
    async fetchProviders({ commit, state }) {
      if (!state.token) {
        commit('SET_TOKEN')
      }
      let response = await dataApi.getProviders(state.token)
      if (response.data) {
        commit('SET_PROVIDERS', response.data)
      }
    },
  },
}
