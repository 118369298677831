import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    imdbId: '',
    title: '',
    year: '',
    rating: '',
    type: '',
    providers: [],
    keywords: [],
    mostLikeTitles: [],
    youTubeId: '',
    state: null,
  },
  getters: {
    getImdbId: (state) => () => {
      return state.imdbId
    },
    getTitle: (state) => () => {
      return state.title
    },
    getYear: (state) => () => {
      return state.year
    },
    getRating: (state) => () => {
      return state.rating
    },
    getType: (state) => () => {
      if (state.type) {
        switch (state.type.toUpperCase()) {
          case '01-MOVIE':
          case '02-VIDEO':
          case '11-TVMOVIE':
          case 'TV-14SPECIAL':
          case '99-OTHER':
          case '21-SHORT':
            return 'Movie'
          case '03-DOCUMENTARY':
            return 'Documentary'
          case '12-TVMINI-SERIES':
          case '13-TVSERIES':
            return 'Series'
          default:
            return 'Movie'
        }
      } else {
        return 'Movie'
      }
    },
    isSeries: (state) => () => {
      if (state.type.toUpperCase() === '13-TVSERIES') {
        return true
      } else {
        return false
      }
    },
    getProviders: (state) => () => {
      return state.providers
    },
    getKeywords: (state) => () => {
      return state.keywords
    },
    getMostLikeTitles: (state) => () => {
      return state.mostLikeTitles
    },
    getYouTubeId: (state) => () => {
      return state.youTubeId
    },
  },
  mutations: {
    SET_IMDB_ID(state, data) {
      state.imdbId = data
    },
    SET_PROVIDERS(state, data) {
      state.providers = data
    },
    SET_KEYWORDS(state, data) {
      state.keywords = data
    },
    SET_MOSTLIKE_TITLES(state, data) {
      state.mostLikeTitles = data
    },
    SET_TITLE_NAME(state, data) {
      state.title = data
    },
    SET_TITLE_YEAR(state, data) {
      state.year = data
    },
    SET_TITLE_RATING(state, data) {
      state.rating = data
    },
    SET_TITLE_TYPE(state, data) {
      state.type = data
    },
    SET_YOUTUBE_ID(state, data) {
      state.youTubeId = data
    },
    CLEAR_TITLE(state) {
      state.title = ''
    },
    CLEAR_TYPE(state) {
      state.type = ''
    },
    CLEAR_YEAR(state) {
      state.year = ''
    },
    CLEAR_RATING(state) {
      state.rating = ''
    },
    CLEAR_KEYWORDS(state) {
      state.keywords = []
    },
    CLEAR_PROVIDERS(state) {
      state.providers = []
    },
    CLEAR_MOSTLIKE_TITLES(state) {
      state.mostLikeTitles = []
    },
    CLEAR_YOUTUBE_ID(state) {
      state.youTubeId = ''
    },
    SET_PROGRESS_INDICATOR_ON() {
      this.state.shared.loading = true
    },
    SET_PROGRESS_INDICATOR_OFF() {
      const that = this
      setTimeout(() => {
        that.state.shared.loading = false
      }, 1000)
    },
    SET_TOKEN(state) {
      state.token = this.state.shared.token
    },
  },
  actions: {
    async fetchTitleDetails({ commit, state }, titleId) {
      commit('CLEAR_TITLE')
      commit('CLEAR_YEAR')
      commit('CLEAR_RATING')
      commit('CLEAR_TYPE')
      commit('CLEAR_KEYWORDS')
      commit('CLEAR_PROVIDERS')
      commit('CLEAR_MOSTLIKE_TITLES')
      commit('CLEAR_YOUTUBE_ID')

      commit('SET_PROGRESS_INDICATOR_ON')

      if (!state.token) {
        commit('SET_TOKEN')
      }
      const response = await dataApi.getTitleDetails(state.token, titleId)
      commit('SET_PROGRESS_INDICATOR_OFF')
      if (response.data) {
        response.data[0].name = response.data[0].title
        response.data[0].ncTitleId = titleId
        commit('SET_IMDB_ID', response.data[0].imdbId || '')
        commit('SET_TITLE_NAME', response.data[0].title || '')
        commit('SET_TITLE_YEAR', response.data[0].releaseYear || '')
        commit('SET_TITLE_RATING', response.data[0].crowdRating || '')
        commit('SET_TITLE_TYPE', response.data[0].type || '')
        commit('SET_KEYWORDS', response.data[0].keywords || [])
        commit('SET_MOSTLIKE_TITLES', response.data[0].mostLikeTitles || [])
        commit('SET_YOUTUBE_ID', response.data[0].youTubeTrailerId)
      }
    },
    async fetchTitleProviders({ commit, getters, state }) {
      if (!state.token) {
        commit('SET_TOKEN')
      }
      const imdbId = getters.getImdbId()
      const response = await dataApi.getTitleProviders(state.token, imdbId)
      if (response.data) {
        commit('SET_PROVIDERS', response.data || [])
      }
    },
    async playTitle({ commit, getters, state }, url) {
      if (!state.token) {
        commit('SET_TOKEN')
      }
      const imdbId = getters.getImdbId()

      dataApi.playTitle(
        state.token,
        imdbId,
        url.replace('https://click.justwatch.com/a?', '')
      )
    },
  },
}
