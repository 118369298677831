import { render, staticRenderFns } from "./ProviderOffers.vue?vue&type=template&id=6f2e9730&scoped=true&"
import script from "./ProviderOffers.vue?vue&type=script&lang=js&"
export * from "./ProviderOffers.vue?vue&type=script&lang=js&"
import style0 from "./ProviderOffers.vue?vue&type=style&index=0&id=6f2e9730&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2e9730",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VContainer})
