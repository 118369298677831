<template>
  <v-row justify="center" v-if="getYouTubeId">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="start">
          <p v-bind="attrs" v-on="on" class="text-caption ml-4">
            <!-- <img src="../assets/images/icons/youtube.png" />
            <span>Trailer</span> -->

            <v-icon> mdi-youtube </v-icon> Trailer
          </p>
        </v-row>
      </template>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
              <!-- <img src="../assets/images/icons/close.png" /> -->
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row justify="center" class="mt-12">
          <LazyYoutube
            ref="youtubeLazyVideo"
            :src="`https://www.youtube.com/watch?v=${getYouTubeId}`"
            max-width="720px"
            aspect-ratio="16:9"
            thumbnail-quality="standard"
          />
        </v-row>
        <v-row justify="center" class="mt-12">
          <v-btn
            class="ma-2"
            @click="handleClick('playVideo', 'youtubeLazyVideo')"
          >
            Play
          </v-btn>
          <v-btn
            class="ma-2"
            @click="handleClick('stopVideo', 'youtubeLazyVideo')"
          >
            Stop
          </v-btn>
          <v-btn
            class="ma-2"
            @click="handleClick('pauseVideo', 'youtubeLazyVideo')"
          >
            Pause
          </v-btn>
          <v-btn
            class="ma-2"
            @click="handleClick('resetView', 'youtubeLazyVideo')"
          >
            Reset
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube'
export default {
  components: {
    LazyYoutube,
  },
  props: ['youTubeId'],
  data: () => ({
    dialog: false,
  }),
  methods: {
    handleClick(event, ref) {
      this.$refs[ref][event]()
    },
  },
  computed: {
    getYouTubeId() {
      return this.youTubeId
    },
  },
  watch: {
    dialog: function (newValue) {
      if (!newValue) {
        this.$refs['youtubeLazyVideo']['stopVideo']()
      }
    },
  },
  created() {
    // console.log(`https://www.youtube.com/watch?v=${this.youTubeId}`)
  },
  mounted() {
    // console.log(`https://www.youtube.com/watch?v=${this.youTubeId}`)
  },
}
</script>

<style></style>
