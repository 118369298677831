<template>
  <div v-if="hasTitles">
    <div v-for="(provider, index) in getProviderTitles" :key="index">
      <v-card width="100%">
        <v-container fluid class="pl-0 pr-0">
          <nc-title-collection
            :title="provider.providerId"
            :items="provider"
            type="provider"
          ></nc-title-collection>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import NcTitleCollection from '../components/NcTitleLists/NcTitleCollection'
export default {
  props: ['tier'],
  components: {
    NcTitleCollection,
  },
  data: () => ({
    selected: '',
  }),
  methods: {
    getTitleList(provider) {
      return provider.subscriptions.allTitles
    },
  },
  computed: {
    hasTitles() {
      return !!this.tier.length
    },
    getProviderTitles() {
      const result = this.tier
      return result
    },
    getProviderImage() {
      return (providerId) => {
        const imageFile =
          this.$store.getters['providers/getProviderImage'](providerId)
        return imageFile
      }
    },
    getProviderSubUrl() {
      return (providerId) => {
        const url =
          this.$store.getters['providers/getProviderSubUrl'](providerId)
        return url || ''
      }
    },
    getProviderInfo() {
      return (providerId) => {
        const label =
          this.$store.getters['providers/getProviderName'](providerId)
        const url =
          this.$store.getters['providers/getProviderSubUrl'](providerId)
        const synopsis =
          this.$store.getters['providers/getProviderSynopsis'](providerId)
        return { label, url, synopsis }
      }
    },
  },
  mounted() {
    this.selected = this.$route.params.tierKey.replaceAll('|', ' | ')
  },
}
</script>

<style scoped>
.list-background {
  background-color: #121212;
  /* background-color: gray; */
}
</style>
