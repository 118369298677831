<template>
  <div class="keyword-top-padding">
    <v-card class="mb-1" flat outlined height="190" width="100%">
      <nc-keyword-list-dialog
        :items="items"
        :tier="tier"
      ></nc-keyword-list-dialog>
      <nc-keyword-horizontal-list
        :items="items"
        class="keyword-list pa-0"
      ></nc-keyword-horizontal-list>
    </v-card>
  </div>
</template>

<script>
import NcKeywordListDialog from './NcKeywordListDialog'
import NcKeywordHorizontalList from './NcKeywordHorizontalList'
export default {
  components: {
    NcKeywordListDialog,
    NcKeywordHorizontalList,
  },

  name: 'NcKeywordCollection',
  props: ['items', 'tier'],
}
</script>

<style scoped>
.keyword-top-padding {
  padding-top: 54px;
}
</style>
