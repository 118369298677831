<template>
  <div>
    <app-toolbar id="tierthree" title="Nanocrowd"></app-toolbar>
    <v-card v-if="hasTitles" flat class="pt-16">
      <v-container fluid class="pa-0">
        <v-card-title
          class="
            d-inline-block
            text-truncate
            ml-4
            mb-4
            dotted-left-border
            red-border
          "
          style="max-width: 350px"
        >
          Click a title to watch. Make popcorn!
        </v-card-title>
        <nc-title-collection
          :title="getSelected"
          :items="getTitles"
          type="tier"
        ></nc-title-collection>
      </v-container>
    </v-card>

    <v-card v-if="hasProviders" width="100%">
      <v-container fluid class="pb-0 pl-0 pr-0">
        <v-card-title class="pb-0 text-caption text-lg-h6">
          {{ getScreenText }}
        </v-card-title>
        <provider-list tier="tierthree"></provider-list>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppToolbar from '../components/AppToolbar'
import ProviderList from '../components/ProviderList'
import NcTitleCollection from '../components/NcTitleLists/NcTitleCollection'
import ProviderTypes from '../shared/ProviderTypes'

export default {
  name: 'tierThree',
  props: ['tierKey'],
  components: {
    AppToolbar,
    ProviderList,
    NcTitleCollection,
  },
  data: () => ({}),
  methods: {
    handleScroll() {
      this.$store.dispatch('tierthree/setScrollPosition', window.scrollY)
    },
  },
  computed: {
    hasTitles() {
      const data = this.$store.getters['tierthree/getTitles']()
      return data.length > 0 ? true : false
    },
    hasProviders() {
      const data = this.$store.getters[`tierthree/getProviderTitles`](
        ProviderTypes.STREAMING
      )
      return data.length > 0 ? true : false
    },
    getTitles() {
      const data = this.$store.getters['tierthree/getTitles']()
      return data.slice(0, 100)
    },
    getSelected() {
      const firstKeyword = this.$store.getters['start/getSelected']()
      const secondKeyword = this.$store.getters['tierone/getSelected']()
      const thirdKeyword = this.$store.getters['tiertwo/getSelected']()
      return `Results for ${firstKeyword} | ${secondKeyword} | ${thirdKeyword}`
    },
    getScreenText() {
      if (this.$store.getters.isStreamingOffers()) {
        return 'Subscription'
      } else if (this.$store.getters.isFreeWithAdsOffers()) {
        return 'Free with ads'
      } else if (this.$store.getters.isCableBundledOffers()) {
        return 'Cable / bundles'
      } else {
        return 'Loading offers...'
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  async mounted() {
    const idParts = this.tierKey.split('|')
    await this.$store.dispatch('shared/getToken')
    this.$store.dispatch('start/setSelected', idParts[0])
    this.$store.dispatch('tierone/setSelected', idParts[1])
    this.$store.dispatch('tiertwo/setSelected', idParts[2])

    const currentKeywords = this.$store.getters['getCurrentKeywords']()
    if (currentKeywords !== this.tierKey) {
      this.$store.dispatch('tierthree/fetchKeywordsAndTitles', this.tierKey)
      this.$store.dispatch('setCurrentKeywords', this.tierKey)
    }
    this.$store.dispatch('setCurrentLevel', 4)
    const scrollPosition = this.$store.getters['tierthree/getScrollPosition']()
    window.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth',
    })
    window.addEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped>
.keyword {
  color: #ff8721;
}
.dotted-left-border {
  border-left-style: dotted;
  border-color: #ffc12d;
}

.yellow-text {
  color: #ffc12d;
}

.list-background {
  background-color: #121212;
  /* background-color: gray; */
}
</style>
