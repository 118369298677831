import { render, staticRenderFns } from "./NcKeywordListDialog.vue?vue&type=template&id=4d90d812&scoped=true&"
import script from "./NcKeywordListDialog.vue?vue&type=script&lang=js&"
export * from "./NcKeywordListDialog.vue?vue&type=script&lang=js&"
import style0 from "./NcKeywordListDialog.vue?vue&type=style&index=0&id=4d90d812&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d90d812",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VCard,VDialog,VIcon,VSpacer,VToolbar,VToolbarTitle})
