<template>
  <div class="text-center">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="90"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters['shared/getLoading']()
    },
  },
}
</script>

<style scoped></style>
